.login-wrapper {
    width: 25vw;
    min-width: 350px;
    margin: 5vh auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.admin-table {
    height: calc(100vh - 139px);
}

.login-wrapper-mobile {
    margin: 10px auto;
    max-width: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.page {
    padding: 20px 20px;
    height: calc(100vh - 58px);
    overflow-y: hidden;
}

.page-mobile {
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.card {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    height: calc(100vh - 98px);
    overflow-x: hidden;
}

.card-mobile {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /*height: calc(100vh - 61px);*/
    overflow-x: hidden;
}


.btn-login {
    background: #FF7A00;
    color: white;
    width: 100%;
    height: 50px;
    font-size: 110%;
    text-transform: uppercase;
}

    .btn-login:nth-child(1):hover {
        background: #FF9D22;
    }

.small-text-polotic {
    font-size: 65%;
    color: #A3A3A3;
}

.svg-logo {
    padding-top: 100px;
    padding-left: 100px;
}

.svg-logo-mobile {
    height: 100%;
    position: relative;
}

.logo-text {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.page-login {
    height: 100vh;
    background: #EDFAF8;
}

.page-login-mobile {
    /*height: calc(100vh - 60px);*/
    background: #EDFAF8;
}

.form-login {
    padding: 30px 50px 30px 50px;
    background: white;
}

.form-login-mobile {
    padding: 15px;
    background: white;
}

.inline-link-login-or-register div {
    width: auto;
    display: inline-block;
}

.main-page {
    height: 100vh;
    background: lightgray;
}

.main-page-mobile {
    background: #ffffff;
    overflow: auto;
}

.content-in-page {
    width: calc(100vw - 120px);
}

.portfolio-content {
    height: calc(100vh - 330px);
}

.portfolio-content-table {
    height: calc(100vh - 370px);
    font-size: 90%;
}

.portfolio-panelbar {
    margin-top: 10px;
}

.border-description-position {
    border: 1px solid darkgrey;
    border-radius: 5px;
    padding:10px;
    margin: 5px;
    font-size: 90%;
}


.chart-equity {
    overflow-x: hidden;
}

.payouts-text-size {
    font-size: 95%;
}

.bonds-panel {
    padding: 0px 10px 0px 10px;
}

.upcoming-offers {
    color: dodgerblue;
    font-size: 90%;
    padding: 5px 0px 5px 0px;
}

.payout-bottom-block {
    overflow-x: hidden;
    padding-left: 10px;
    padding-right: 10px;
}

.nav-title {
    font-size: 18px;
    margin: 0;
    margin-left: 20px;
    margin-right: 10px;
}

.nav-title-page {
    padding: 10px 0px 10px 0px;
    font-size: 18px;
    margin: 0;
    margin-left: 10px;
    margin-right: 20px;
}

a.active {
    font-weight: bold
}

.cursor-pointer table {
    cursor: pointer;
}

td.cursor-pointer {
    cursor: pointer
}

.link-policy {
    text-decoration: underline;
    cursor: pointer;
    color: #A3A3A3;
}

input {
    outline: none;
}


/*input style override*/
.k-input {
    color: #495057;
    background-color: rgba(30,221,132,0.05);
}

/*input style override*/
.k-input-solid:focus-within {
    border-color: #1EDD84;
    color: #495057;
    background-color: rgba(30,221,132,0.05);
    box-shadow: 0 0 0 .25rem rgba(30,221,132,0.05)
}

a.nav-link {
    cursor: pointer;
    margin-left: 5px;
    text-decoration: underline;
    text-transform: lowercase;
}

.info-panel-mobile {
    /*height: calc(100vh - 128px);*/
    overflow: auto;
}

.info-panel-border {
    border: 2px solid darkgrey;
    border-radius: 5px;
    color: dodgerblue;
    margin: 10px;
    padding: 10px;
    text-align: center;
    height: 160px;
    overflow-y: hidden;
}

.info-panel-border-mobile {
    background: white;
    border: 1px solid darkgrey;
    border-radius: 5px;
    color: dodgerblue;
    margin: 5px 10px 5px 5px;
    padding-top: 10px;
    text-align: center;
}

.dashboard-link {
    margin-left: 10px;
}

.info-panel-text-header {
    font-size: 115%;
}

.info-panel-text-header-mobile {
    font-size: 100%;
}

.info-panel-text-value {
    font-weight: bold;
    font-size: 210%;
}

.info-panel-text-value-mobile {
    font-weight: bold;
    font-size: 150%;
}

.info-panel-text-description {
    font-size: 80%;
}

.info-panel-text-description-mobile {
    font-size: 80%;
    padding-bottom: 10px;
}

.dashboard-chip-list {
    width: calc(100vw - 35px);
    overflow-x: auto;
    margin-left: 5px;
    padding: 0px 0px 10px 0px; 
}

.equity-chip-list {
    position: relative;
    width: calc(100vw - 35px);
    margin-top: -80px;
    overflow-x: auto;
    margin-left: 0px;
    padding: 0px 0px 10px 0px;
}

.equity-properties {
    display: inline-block;
    width: calc(100vw - 20px);
    overflow-x: auto;
    margin-left: 0px;
    padding: 0px 0px 10px 0px;
    flex-wrap: nowrap;
}


.ideas-input {
    width: 150px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.ideas-combobox {
    width: 160px;
    margin-bottom: 10px;
    padding-left: 10px;
}

.ideas-table-column {
    margin-right: 5px;
    border: 1px solid darkgrey;
    height: calc(100vh - 176px );
    min-width: 400px;
}

.ideas-description-column {
    height: 220px;
    padding: 10px;
    margin-right: 5px;
    border: 1px solid darkgrey;
    color: dodgerblue;
}

.ideas-parameters-column {
    height: 220px;
    margin-left: 5px;
    padding: 10px;
    border: 1px solid darkgrey;
    color: dodgerblue;
}

.ideas-notes-component {
    border: 1px solid darkgrey;
    height: calc(100vh - 476px );
}

.idea-notes-table {
    height: calc(100vh - 610px );
}

.idea-text-color {
    color: dodgerblue;
    margin: 0px;
}

.idea-text-parameters {
    margin: 0px;
    margin-left: 5px;
    color: dodgerblue;
}

.idea-parameters-row {
    height: 36px;
}

.idea-button-addnote {
    text-align: right;
    margin: 10px;
}

.idea-hint {
    margin-left: 10px;
    color: darkgrey;
}

.idea-text-note {
    margin-top: 10px;
    height: 60px;
    scroll-margin: initial;
}

.idea-right-block {
    margin-left: 5px;
    min-width: 500px;
}



.dashboard-info-links {
    width: calc(100vw - 48px);
    background: khaki;
    padding: 10px;
    margin-bottom: 5px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: left;
    border-radius: 10px;
}

.dashboard-all-info {
    /*height: calc(100vh - 265px);*/
    overflow: auto;
    height: 100vh;
}

.dasboard-text-links-used-data {
    white-space: pre-line;
    font-size: 80%;
}

.dasboard-btn-close-info {
    float: right;
}

.dashboard-chart-border {
    border: 2px solid darkgrey;
    color: dodgerblue;
    border-radius: 5px;
    height: calc(41vh - 100px);
    margin: 10px;
    text-align: center;
    flex-flow: row nowrap;
}

.dashboard-chart-border-mobile {
    border: 2px solid darkgrey;
    color: dodgerblue;
    border-radius: 5px;
    margin: 10px;
    text-align: center;
    flex-flow: row nowrap;
}

.dashboard-chart-border-min {
    border: 2px solid darkgrey;
    color: dodgerblue;
    
    margin: 10px;
    text-align: center;
    flex-flow: row nowrap;
    border-radius: 5px;
}

.dashboard-chart {
    width: 60%;
    height: calc(40vh - 100px);
    float: left;
}

.dashboard-chart-mobile {
    width: calc(100vw - 45px);
    height: calc(100vw - 45px);
}

.dashboard-chart-min {
    width: 60%;
    height: 23vh;
    float: left;
}

.list-legend-height {
    overflow: auto;
    height: calc(37vh - 115px);
}

.list-legend-height-min {
    overflow: auto;
    height: 17vh;
}


.dashboard-chart-legend {
    width: 40%;
    float: right;
    overflow: scroll;
}

.dashboard-chart-legend-mobile {
    float: right;
    overflow: scroll;
}

.block-legend {
    text-align: center;
    padding: 5px;
    display: flex;
    flex-direction: row;
}

.color-legend {
    width: 10%;
    float: left;
    margin: auto;
}

.text-legend {
    width: 90%;
    float: right;
}

.dashboard-no-data-load-linksinfo-vis {
    height: calc(100vh - 265px);
    width: 100%;
    text-align: center;
    display: flex;
}

.dashboard-no-data-load {
    height: calc(100vh - 128px);
    width: 100%;
    text-align: center;
    display: flex;
}

.dashboard-no-data-load-mobile {
    width: 100%;
    text-align: center;
    display: flex;
}

.dashboard-no-data-text {
    color: darkorange;
    font-size: 160%;
}

.loader-page {
    height: calc(100vh - 128px);
    width: 100%;
    text-align: center;
    display: flex;
}

.accounts-import-report {
    width: auto;
    margin-bottom: 10px;
}

.accounts-clear {
    width: auto;
    margin-left: 10px;
    margin-bottom: 10px;
}

.account-progress {
    margin-top: -15px;
}

.account-table-column {
    margin-right: 5px;
    border: 1px solid darkgrey;
    height: calc(100vh - 176px );
    min-width: 400px;
}

.account-table-column-min {
    margin-right: 5px;
    border: 1px solid darkgrey;
    height: calc(100vh - 220px );
    min-width: 400px;
}

.border-description-account {
    border: 1px solid darkgrey;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}
.btn-dialog {
    float: right;
    margin-top: 15px;
    margin-left: 10px;
}

.fieldset {
    border: 1px solid darkgrey;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 5px 5px 5px 15px;
}

.fieldset-grid {
    border: 1px solid darkgrey;
    border-radius: 5px;
    margin-bottom: 1px;
    padding: 5px 5px 0px 5px;
}

.legend {
    font-size: 90%;
    margin-top: -6px;
    margin-bottom: -2px;
    padding-left: 5px;
}

.legend-table {
    font-size: 90%;
    margin-top: 0px;
    margin-bottom: 5px;
    margin-left: -5px;
}

.loader-over {
    position: absolute;
    z-index: 3;
    background: rgba(243, 243, 243, 0.6);
    /*margin: -20px;
    height: 100vh;*/
    width: -webkit-fill-available;
}

.cash-balances-info {
    padding: 10px 10px 0px 10px;
    color: dodgerblue;
    border: 1px solid darkgrey;
    border-radius: 5px;
    margin-top: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    font-size: 95%;
}

.total-label {
    padding: 10px;
    background: #f5f5f5;
}

.link-instruction-report {
    text-align: right;
    margin-top: -5px;
    padding-bottom: 15px;
}

.link-instruction-report-mobile {
    text-align: right;
    margin-top: -5px;
    padding-bottom: 15px;
    font-size: 85%;
}

.text-help {
    padding-left: 10px;
    margin-bottom: -10px;
    font-size: 100%;
}

.help-page {
    height: calc(100vh - 128px);
    overflow-y: auto;
}

.profit-fixer-table {
    margin-top: 15px;
    height: calc(100vh - 210px);
    font-size: 90%;
}

.table-correlation {
    height: calc(100vh - 210px);
    font-size: 90%;
    overflow: visible;
    word-wrap: break-word;
    margin-top: 15px;
}